body {
  background-color: #2f3544;
}

.App {
  text-align: center;
}

.input-group {
  width: 60%;
}

.card {
  max-width: 60%;
}

.card-title {
  position: absolute;
  padding: 20px;
}

.card-date {
  position: absolute;
  padding-left: 20px;
  margin-top: 60px;
}

.card-temp {
  position: absolute;
  padding-left: 20px;
  margin-top: 260px;
  font-size: 50px;
}

.card-desc {
  position: absolute;
  padding-left: 20px;
  margin-top: 320px;
}
